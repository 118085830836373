import React, { useEffect, useState } from "react";
import { RegisterAPI } from "../../API";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import CBCLogo from "../../assets/SVG/CBCLogo";
import ShowIcon from "../../assets/SVG/ShowIcon";
import Form from "react-bootstrap/Form";
import WarningIcon from "../../assets/SVG/WarningIcon";
import { useParams } from 'react-router-dom';
import EmailIcon from "../../assets/SVG/EmailIcon";


export default function Registro() {
  let navigate = useNavigate();
  const invalid_email = "Formato mail inválido";
  const params = useParams();
  const pre_code = params.params?.substring(5);
  console.log("pre_code", pre_code);
  const [referalCode, setReferalCode] = useState(pre_code ? pre_code : "");
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState()
  const [username, setUserName] = useState();
  const [country, setCountry] = useState([]);
  const [phone, setPhone] = useState();
  const [birthday, setBirthday] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showRegistered, setShowRegistered] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  // Errores
  const [showFirstnameError, setShowFirstnameError] = useState(null);
  const [showLastnameError, setShowLastnameError] = useState(null);
  const [showUsernameError, setShowUsernameError] = useState(null);
  const [showCountryError,setShowCountryError] = useState(null);
  const [showPhoneError,setShowPhoneError] = useState(null);
  const [showBirthdayError,setShowBirthdayError] = useState(null);
  const [showEmailError, setShowEmailError] = useState(null);
  const [showPasswordError,setShowPasswordError] = useState(null);
  const [showAcceptTermsError,setShowAcceptTermsError] = useState(null);
  
  const optionsCountry = [
    { label: "Afghanistan", value: "AF" },
    { label: "Albania", value: "AL" },
    { label: "Algeria", value: "DZ" },
    { label: "Andorra", value: "AD" },
    { label: "Angola", value: "AO" },
    { label: "Antigua and Barbuda", value: "AG" },
    { label: "Argentina", value: "AR" },
    { label: "Armenia", value: "AM" },
    { label: "Australia", value: "AU" },
    { label: "Austria", value: "AT" },
    { label: "Azerbaijan", value: "AZ" },
    { label: "Bahamas", value: "BS" },
    { label: "Bahrain", value: "BH" },
    { label: "Bangladesh", value: "BD" },
    { label: "Barbados", value: "BB" },
    { label: "Belarus", value: "BY" },
    { label: "Belgium", value: "BE" },
    { label: "Belize", value: "BZ" },
    { label: "Benin", value: "BJ" },
    { label: "Bhutan", value: "BT" },
    { label: "Bolivia", value: "BO" },
    { label: "Bosnia and Herzegovina", value: "BA" },
    { label: "Botswana", value: "BW" },
    { label: "Brazil", value: "BR" },
    { label: "Brunei", value: "BN" },
    { label: "Bulgaria", value: "BG" },
    { label: "Burkina Faso", value: "BF" },
    { label: "Burundi", value: "BI" },
    { label: "Cabo Verde", value: "CV" },
    { label: "Cambodia", value: "KH" },
    { label: "Cameroon", value: "CM" },
    { label: "Canada", value: "CA" },
    { label: "Central African Republic", value: "CF" },
    { label: "Chad", value: "TD" },
    { label: "Chile", value: "CL" },
    { label: "China", value: "CN" },
    { label: "Colombia", value: "CO" },
    { label: "Comoros", value: "KM" },
    { label: "Congo (Republic of the)", value: "CG" },
    { label: "Congo (Democratic Republic of the)", value: "CD" },
    { label: "Costa Rica", value: "CR" },
    { label: "Croatia", value: "HR" },
    { label: "Cuba", value: "CU" },
    { label: "Cyprus", value: "CY" },
    { label: "Czech Republic", value: "CZ" },
    { label: "Denmark", value: "DK" },
    { label: "Djibouti", value: "DJ" },
    { label: "Dominica", value: "DM" },
    { label: "Dominican Republic", value: "DO" },
    { label: "East Timor (Timor-Leste)", value: "TL" },
    { label: "Ecuador", value: "EC" },
    { label: "Egypt", value: "EG" },
    { label: "El Salvador", value: "SV" },
    { label: "Equatorial Guinea", value: "GQ" },
    { label: "Eritrea", value: "ER" },
    { label: "Estonia", value: "EE" },
    { label: "Eswatini", value: "SZ" },
    { label: "Ethiopia", value: "ET" },
    { label: "Fiji", value: "FJ" },
    { label: "Finland", value: "FI" },
    { label: "France", value: "FR" },
    { label: "Gabon", value: "GA" },
    { label: "Gambia", value: "GM" },
    { label: "Georgia", value: "GE" },
    { label: "Germany", value: "DE" },
    { label: "Ghana", value: "GH" },
    { label: "Greece", value: "GR" },
    { label: "Grenada", value: "GD" },
    { label: "Guatemala", value: "GT" },
    { label: "Guinea", value: "GN" },
    { label: "Guinea-Bissau", value: "GW" },
    { label: "Guyana", value: "GY" },
    { label: "Haiti", value: "HT" },
    { label: "Honduras", value: "HN" },
    { label: "Hungary", value: "HU" },
    { label: "Iceland", value: "IS" },
    { label: "India", value: "IN" },
    { label: "Indonesia", value: "ID" },
    { label: "Iran", value: "IR" },
    { label: "Iraq", value: "IQ" },
    { label: "Ireland", value: "IE" },
    { label: "Israel", value: "IL" },
    { label: "Italy", value: "IT" },
    { label: "Jamaica", value: "JM" },
    { label: "Japan", value: "JP" },
    { label: "Jordan", value: "JO" },
    { label: "Kazakhstan", value: "KZ" },
    { label: "Kenya", value: "KE" },
    { label: "Kiribati", value: "KI" },
    { label: "Kosovo", value: "XK" },
    { label: "Kuwait", value: "KW" },
    { label: "Kyrgyzstan", value: "KG" },
    { label: "Laos", value: "LA" },
    { label: "Latvia", value: "LV" },
    { label: "Lebanon", value: "LB" },
    { label: "Lesotho", value: "LS" },
    { label: "Liberia", value: "LR" },
    { label: "Libya", value: "LY" },
    { label: "Liechtenstein", value: "LI" },
    { label: "Lithuania", value: "LT" },
    { label: "Luxembourg", value: "LU" },
    { label: "Madagascar", value: "MG" },
    { label: "Malawi", value: "MW" },
    { label: "Malaysia", value: "MY" },
    { label: "Maldives", value: "MV" },
    { label: "Mali", value: "ML" },
    { label: "Malta", value: "MT" },
    { label: "Marshall Islands", value: "MH" },
    { label: "Mauritania", value: "MR" },
    { label: "Mauritius", value: "MU" },
    { label: "Mexico", value: "MX" },
    { label: "Micronesia", value: "FM" },
    { label: "Moldova", value: "MD" },
    { label: "Monaco", value: "MC" },
    { label: "Mongolia", value: "MN" },
    { label: "Montenegro", value: "ME" },
    { label: "Morocco", value: "MA" },
    { label: "Mozambique", value: "MZ" },
    { label: "Myanmar (Burma)", value: "MM" },
    { label: "Namibia", value: "NA" },
    { label: "Nauru", value: "NR" },
    { label: "Nepal", value: "NP" },
    { label: "Netherlands", value: "NL" },
    { label: "New Zealand", value: "NZ" },
    { label: "Nicaragua", value: "NI" },
    { label: "Niger", value: "NE" },
    { label: "Nigeria", value: "NG" },
    { label: "North Korea", value: "KP" },
    { label: "North Macedonia (Macedonia)", value: "MK" },
    { label: "Norway", value: "NO" },
    { label: "Oman", value: "OM" },
    { label: "Pakistan", value: "PK" },
    { label: "Palau", value: "PW" },
    { label: "Palestine", value: "PS" },
    { label: "Panama", value: "PA" },
    { label: "Papua New Guinea", value: "PG" },
    { label: "Paraguay", value: "PY" },
    { label: "Peru", value: "PE" },
    { label: "Philippines", value: "PH" },
    { label: "Poland", value: "PL" },
    { label: "Portugal", value: "PT" },
    { label: "Qatar", value: "QA" },
    { label: "Romania", value: "RO" },
    { label: "Russia", value: "RU" },
    { label: "Rwanda", value: "RW" },
    { label: "Saint Kitts and Nevis", value: "KN" },
    { label: "Saint Lucia", value: "LC" },
    { label: "Saint Vincent and the Grenadines", value: "VC" },
    { label: "Samoa", value: "WS" },
    { label: "San Marino", value: "SM" },
    { label: "Sao Tome and Principe", value: "ST" },
    { label: "Saudi Arabia", value: "SA" },
    { label: "Senegal", value: "SN" },
    { label: "Serbia", value: "RS" },
    { label: "Seychelles", value: "SC" },
    { label: "Sierra Leone", value: "SL" },
    { label: "Singapore", value: "SG" },
    { label: "Slovakia", value: "SK" },
    { label: "Slovenia", value: "SI" },
    { label: "Solomon Islands", value: "SB" },
    { label: "Somalia", value: "SO" },
    { label: "South Africa", value: "ZA" },
    { label: "South Korea", value: "KR" },
    { label: "South Sudan", value: "SS" },
    { label: "Spain", value: "ES" },
    { label: "Sri Lanka", value: "LK" },
    { label: "Sudan", value: "SD" },
    { label: "Suriname", value: "SR" },
    { label: "Sweden", value: "SE" },
    { label: "Switzerland", value: "CH" },
    { label: "Syria", value: "SY" },
    { label: "Taiwan", value: "TW" },
    { label: "Tajikistan", value: "TJ" },
    { label: "Tanzania", value: "TZ" },
    { label: "Thailand", value: "TH" },
    { label: "Togo", value: "TG" },
    { label: "Tonga", value: "TO" },
    { label: "Trinidad and Tobago", value: "TT" },
    { label: "Tunisia", value: "TN" },
    { label: "Turkey", value: "TR" },
    { label: "Turkmenistan", value: "TM" },
    { label: "Tuvalu", value: "TV" },
    { label: "Uganda", value: "UG" },
    { label: "Ukraine", value: "UA" },
    { label: "United Arab Emirates", value: "AE" },
    { label: "United Kingdom", value: "GB" },
    { label: "United States of America", value: "US" },
    { label: "Uruguay", value: "UY" },
    { label: "Uzbekistan", value: "UZ" },
    { label: "Vanuatu", value: "VU" },
    { label: "Vatican City (Holy See)", value: "VA" },
    { label: "Venezuela", value: "VE" },
    { label: "Vietnam", value: "VN" },
    { label: "Yemen", value: "YE" },
    { label: "Zambia", value: "ZM" },
    { label: "Zimbabwe", value: "ZW" }
];



const filterOptionsCountry = (options, filter) => {
  // Convertir el filtro a minúsculas para hacer la comparación insensible a mayúsculas/minúsculas
  const filterLowerCase = filter.toLowerCase();
  // Filtrar las opciones por nombre de país o por código de país
  const filteredOptions = options.filter(option => {
      // Verificar si el nombre del país o el código de país incluyen el filtro
      return option.label.toLowerCase().includes(filterLowerCase) || option.value.includes(filterLowerCase);
  });

  return filteredOptions || options;
};

function isEmail(value) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(value)) {
        return false
      } else {
        return true;
      }
  }


  function isDate(value) {
    let regDate = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(\d{4})$/;
    
    if (!regDate.test(value)) {
      return false
    } else {
      return true;
    }
}


useEffect(() => {
  setShowFirstnameError(null);
  setShowLastnameError(null);
  setShowUsernameError(null);
  setShowCountryError(null);
  setShowPhoneError(null);
  setShowBirthdayError(null);
  setShowPasswordError(null);
  setShowEmailError(null);
  setShowAcceptTermsError(null);
}, [firstName,lastName,email,username,country,phone,birthday,password,confirmPassword,acceptTerms]);



useEffect(() => {
  let timer;
  if (showRegistered) {
    timer = setTimeout(() => {
      navigate("../../login/");
    }, 15000); // 30 segundos
  }

  return () => clearTimeout(timer); // Limpia el temporizador
}, [showRegistered, navigate]);




  return (
    <div className="RegisterContainer">
    <div className="RegisterLayout">
    <div  style={{ height:"50px", width:"50px"}} />
    <CBCLogo width="115" height="115" color="white" />
    { !showRegistered && 
    <Form className="LoginForm"
          onSubmit={(e) => {
            e.preventDefault();

            const is_complete = firstName && lastName && birthday && isDate(birthday) && country && password && confirmPassword && password == confirmPassword && email && isEmail(email) && phone && username && acceptTerms
            if(is_complete){
              RegisterAPI(firstName,lastName,username,country[0],phone,birthday,email,password,referalCode).then( res => {
                console.log(res)
                if(res.status == 201){
                  setShowRegistered(true)
                }
              })
            }
            else{
              setShowFirstnameError(firstName ? null :  "Complete Nombre \n");
              setShowLastnameError(lastName ? null :  "Complete Apellido \n ");
              setShowUsernameError(username ? null : "Complete Nombre Usuario \n");
              setShowCountryError(country.length>0 ? null : "Complete País\n");
              setShowPhoneError(phone ? null : "Complete teléfono. Use el formato 56123456789 \n");
              setShowBirthdayError(isDate(birthday) ? null : "Fecha inválida. Usa el formato dd/mm/aaaa \n");
              setShowEmailError(email && isEmail(email) ? null : invalid_email)
              setShowPasswordError(password && confirmPassword && password === confirmPassword ? null : "Contraseñas no coinciden \n" ); 
              setShowAcceptTermsError(acceptTerms ? null : "Acepta los términos y condiciones \n");
            }
          }}>
      {(showFirstnameError || showLastnameError || showUsernameError || showCountryError ||  showPhoneError || showBirthdayError || showEmailError || showPasswordError || showAcceptTermsError) && 
      <div  className="ErrorText">
        <WarningIcon /> 
        <div style={{width:"89%",display:"flex",flexDirection:"column"}}> 
          <span>{showFirstnameError} </span>
          <span>{showLastnameError} </span>
          <span>{showUsernameError} </span>
          <span>{showCountryError} </span>
          <span>{showPhoneError} </span>
          <span>{showBirthdayError} </span>
          <span>{showEmailError}</span>
          <span>{showPasswordError}</span>
          <span>{showAcceptTermsError}</span>
        </div>
      </div>}
      
      <div className="RegisterBox">
      ¡Regístrate y se parte de nuestra comunidad!
      <div className={showFirstnameError ?"LoginInput ErrorBorder" :"LoginInput"}>
        <input
          placeholder="Nombre*"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
      </div>
      <div className={ showLastnameError ?"LoginInput ErrorBorder" :"LoginInput"}>
        <input
          placeholder="Apellido*"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
      </div>
      <div className={showUsernameError ?"LoginInput ErrorBorder" :"LoginInput"}>
        <input
          placeholder="Nombre de Usuario*"
          value={username}
          onChange={(e) => {
            setUserName(e.target.value.trim().toLocaleLowerCase());
          }}
        />
      </div>
      <div className={showCountryError  ?"LoginInput ErrorBorder" :"LoginInput"}>
      <MultiSelect
            options={optionsCountry}
            value={country}
            onChange={(value) => setCountry(value.length > 0 ? [value[value.length - 1]] : [])}
            labelledBy="País*"
            closeOnChangedValue={true}
            hasSelectAll={false}
            filterOptions={filterOptionsCountry}
            overrideStrings={{ 
            "noOptions": "Sin resultados",
            "search": "Buscar",
            "selectSomeItems": "País*",
           }}
        />
      </div>
      <div className={showPhoneError ?"LoginInput ErrorBorder" :"LoginInput"} style={{alignItems:"center"}}>
        +
        <input
          placeholder="Teléfono* (5612345678)"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value.trim());
          }}
        />
      </div>
      <div className={showBirthdayError ? "LoginInput ErrorBorder" : "LoginInput"}>
      <input
        placeholder="Cumpleaños* (DDMMAAAA)"
        value={birthday}
        onChange={(e) => {
          let input = e.target.value.replace(/\D/g, ""); 
          if (input.length > 8) input = input.substring(0, 8);
          const formattedBirthday = input
            .replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3")
            .substring(0, 10); 
          setBirthday(formattedBirthday);
        }}
      />
    </div>

      <div className={showEmailError  ?"LoginInput ErrorBorder" :"LoginInput"}>
        <input
          placeholder="Email*"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value.trim().toLocaleLowerCase());
          }}
        />
      </div>

      <div className={showPasswordError ?"LoginInput ErrorBorder" :"LoginInput"}>
        
        <input
          type={!showPassword ? "password" : "text"}
          placeholder="Contraseña*"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />

        <button
          onClick={() => {
            setShowPassword(!showPassword);
          }}
        >
          <ShowIcon show={!showPassword} width="20px" height="20px" />
        </button>
      </div>
      <div className={showPasswordError ?"LoginInput ErrorBorder" :"LoginInput"}>
        <input
          type={!showPassword2 ? "password" : "text"}
          placeholder="Confirma Contraseña*"
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
        />

        <button
          onClick={() => {
            setShowPassword2(!showPassword2);
          }}
        >
          <ShowIcon show={!showPassword2} width="20px" height="20px" />
        </button>
      </div>
      {/*<div className="LoginInput" >
        <input
          placeholder="Código de Referencia"
          value={referalCode}
          onChange={(e) => {
            setReferalCode(e.target.value.trim());
          }}
        />
      </div>  */}     
    </div>
    <div className={showAcceptTermsError ?"LoginVariantButton ErrorBorder" :"LoginVariantButton"} style={{display:"flex",flexDirection: "row",justifyContent: "center", alignItems: "center", gap: "10px",border:(showAcceptTermsError ? "1px solid red" : "unset !important")}}>
      <input
        type="checkbox"
        onChange={(e) => {
          setAcceptTerms(e.target.checked);
        }}
      />
      <span style={{fontSize: "14px"}}>
        Acepto los <button className="LinkButtonDecoration"  onClick={() => navigate("/termsandconditions")}>términos y condiciones</button>
      </span>
    </div>
    <button className="RecentPostButton" type="Submit">
          CREAR CUENTA
    </button>

    <div className="LoginVariantButton">
        ¿Ya tienes cuenta? 
        <button className="LinkButtonDecoration" onClick={() => navigate("/login")}>
        Inicia sesión
        </button>
    </div>
  </Form>
  }
  { showRegistered && 
  <div style={{maxWidth:"350px"}}>
         <div  className="MessageText">
          <EmailIcon />
          <div className="LoginSideText">
          <span style={{fontWeight:"bold"}}>Revisa tu correo electrónico.</span>
          <span>Tienes 24 horas para confirmar tu correo electrónico. Si no recibes el email, revisa tu carpeta spam, o contáctanos a app@cranberrychic.com. <br/>  Estás siendo redireccionado a iniciar sesión...</span>
      
          </div>
    </div>

  </div>
  }
  </div>
  </div>
  );
}


