// LIBRARY IMPORTS
import React, { useEffect, useState } from "react";
import { goBack, confirmLeave } from "../../Utils";
import Form from "react-bootstrap/Form";
import { MultiSelect } from "react-multi-select-component";
import Autocomplete from "react-google-autocomplete";
import { CategoriesAPI,GarmentTypeAPI,GarmentBrandAPI,CreateGarmentAPI,GetPlaceAPI, GarmentColorAPI, PostAPI,EditPostAPI,GarmentByPostAPI} from "../../API";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "react-switch";
import PostFormEdit from "../Components/PostFormeEdit";


// MY CODE IMPORTS
//import RecomendationMenu from "../Components/RecomendationMenu";

import CarouselContent from "../Components/CarouselContent";
import ScrollToTopOnMount from "../Components/ScrollToTopOnMount";



// My icons
import BackIcon from "../../assets/SVG/BackIcon";
import Spinner from "react-bootstrap/Spinner";
import PinIcon from "../../assets/SVG/PinIcon";



function FullPostEditScreen(props) {
  const [data, setData] = useState();
  const { id } = useParams();
  const [me, setMe] = useState();
  const [place, setPlace] = useState();

  let isMobile;
  let box_size;
  let image_size;
  const { innerWidth } = window;

  isMobile = true;
  box_size = innerWidth - 16;
  if (box_size > 500){
    box_size = 500;
  }
  image_size = box_size - 102;

  useEffect(() => {
    PostAPI(id).then((res) => {
      if (res) {
        setData(res);
        const place = res.place;
        if(place){
          GetPlaceAPI(place).then((res_place) => setPlace(res_place));
        }
      }
    });
  }, [id]);


  useEffect(() => {
    if (data?.place) {
      GetPlaceAPI(data.place).then((res) => {
        setPlace(res);
      });
    }
  }, [data]);

  const handleCarouselLoaded = () => {
    console.log("loaded")
  };

  const navigate = useNavigate();

    useEffect(() => {
      const cleanup = confirmLeave(navigate);
      return () => cleanup();
    }, []);

    const handleGoBack = () => {
          goBack(navigate)
    };
    
  
    return (
      <div className="FullPostLayoutMobile">
        <ScrollToTopOnMount />
        <div className="HeaderSpacer" style={{ height: "56px", width: "100vw" }} />
        <div className="FullPostBoxMobile" style={{ width: box_size }} >
          <div className="FullPostHeader">
            <div className="FullPostHeaderLeft">
              <button className="FullPostHeaderButton"  onClick={() => handleGoBack()}>
                <BackIcon />
              </button>
              </div>
            <div className="FullPostHeaderRowButton">
            <button className="FullPostHeaderFollowButton FullPostHeaderFollowButtonVariant"type="submit" form='post-edit-form'>
                Guardar
            </button>
            </div>
          </div>
          <div className="FullPostContentCarousel" style={{
            minWidth: image_size + "px",
            minHeight: data?.contents[0].content_ratio ==="1:1"  ? image_size + "px" : Math.round((image_size / 3) * 4) + "px",
          }}
          >
          {
            data && data.contents.length > 0 &&
          
            <CarouselContent id={id} data={data?.contents} size={image_size} mini={false} ratioSquare={data?.contents[0].content_ratio==="1:1" } onLoad={handleCarouselLoaded}/>
          
          }
          </div>
        <div className="EditPostInputs">
          <PostFormEdit  data={data} place={place}/>
        </div>
        <div className="Spacer" style={{ height:"20px" , width:"10px"}} />     
        </div>
        <div className="Spacer" style={{ height:"150px" , width:"10px"}} /> 
      </div>
    );
  } 

export default FullPostEditScreen;




