import React, { useState,useEffect,useRef} from "react";
import {BenefitAcceptAPI, BenefitAddRecieptAPI,BenefitAddGradeCommentAPI  } from "../../API";
import CarouselContent from "../Components/CarouselContent";
import Form from "react-bootstrap/Form";
import { MultiSelect } from "react-multi-select-component";
import Counter from "./Counter";

import DescuentoIcon from "../../assets/SVG/DescuentoIcon"
import ViajeIcon from "../../assets/SVG/ViajeIcon"
import RestaurantesIcon from "../../assets/SVG/RestaurantesIcon"
import RegaloIcon from "../../assets/SVG/RegaloIcon"
import ClassesIcon from "../../assets/SVG/ClassesIcon";
import ClipIcon from  "../../assets/SVG/ClipIcon";


function Benefit(props) {

    const HtmlToReactParser = require("html-to-react").Parser;
    const assignation= props.data;
    const boxWidth = props.boxWidth;

    const [showUpload,setShowUpload] = React.useState(false);
    const [showMore,setShowMore] =React.useState(false);
    const [showDetails,setShowDetails]=React.useState(false);
    const [overflowActive, setOverflowActive] = useState(false);
    const [isBigger,setIsBigger] =useState(false);
    const textRef = useRef();

    const handleCarouselLoaded = () => {
      console.log("onLoad");
    };

    

    const icons = [
      {
        type : "regalo",
        icon : <RegaloIcon />
      } ,
      {
        type : "descuento",
        icon : <DescuentoIcon />
      } ,
      {
        type : "viaje",
        icon : <ViajeIcon />
      } ,
      {
        type : "restaurantes",
        icon : <RestaurantesIcon />
      } ,
      {
        type : "clases",
        icon : <ClassesIcon />
      } ,
    ]  ;
        
    function handleAccept(){
      BenefitAcceptAPI(true,assignation.id).then(res => window.location.reload(true))
    }
    function handleDecline(){
      BenefitAcceptAPI(false,assignation.id).then(res => window.location.reload(true) )
    }


    function separarElementos(textoHTML) {
      // Eliminar las etiquetas <p> y <li> para obtener solo el contenido
      const contenidoLimpio = textoHTML.replace(/<\/?p>/g, '').replace(/<\/?li>/g, '');
  
      // Utilizar una expresión regular para dividir el texto en elementos basados en las etiquetas <br> y <ol>
      const elementos = contenidoLimpio.split(/<br>|<\/?ol>/);
  
      // Eliminar espacios en blanco al principio y al final de cada elemento y eliminar elementos vacíos
      const elementosFiltrados = elementos.map(elemento => elemento.trim()).filter(elemento => elemento !== '');
  
      return elementosFiltrados;
  }
    
    
    let htmlInput =assignation.benefit.description;
    let htmlToReactParser = new HtmlToReactParser();
    let reactElementFull = htmlToReactParser.parse(htmlInput);
    
    

    function isOverflowActive(event) {
      return event?.offsetHeight < event?.scrollHeight;
    }
  
    useEffect(() => {
      // Asegurarnos de que el ref esté disponible y que el contenido esté completamente cargado.
      const checkOverflow = () => {
        if (textRef.current) {
          if (isOverflowActive(textRef.current)) {
            setOverflowActive(true);
            console.log("hay overflow");
          } else {
            setOverflowActive(false);
            console.log("nohayoverflow");
          }
        }

        const remInPixels = parseFloat(window.getComputedStyle(document.documentElement).fontSize) * 2;

        // Comparar el offsetHeight del elemento con 2rem (en píxeles)
        if (textRef.current.offsetHeight > remInPixels) {
          setIsBigger(true);
          console.log("Es mayor a 2rem");
        } else {
          setIsBigger(false);
          console.log("Es menor o igual a 2rem");
        }
      };
      
      
      checkOverflow();
      
      // Agregamos un listener para recalcular en caso de que cambie el tamaño de la ventana
      window.addEventListener("resize", checkOverflow);
      
      return () => {
        window.removeEventListener("resize", checkOverflow);
      };
    }, [reactElementFull]);
    
 

    const openInNewTab = (url) => {
      window.open(url, "_blank", "noopener,noreferrer");
    };

    function Badge(props){
      const receipts_qty = props.receipts_qty;
      const state = props.state
      if(state === 0){
        return(
          <div className="BenefitBadgeRed">
            Esperando
          </div>
        )
        
      }
      else if (state === 1 && receipts_qty === 0) {
        return(
          <div className="BenefitBadgeRed">
            Esperando Comprobantes
          </div>
        )
       
      }
      else if (state === 1  && receipts_qty>0 ) {
        return(
          <div className="BenefitBadgeGreen">
            Esperando Aprobacion
          </div>
        )
       
      }
      else if (state === 2) {
        return(
        <div className="BenefitBadgeGrey">
          Rechazado
        </div>)

      }
      else if (state === 3) {
        return(
        <div className="BenefitBadgeGrey">
          Ignorado
        </div>)

      }
      else if (state === 4) {
        return(
        <div className="BenefitBadgeGreen">
          Aprobado
        </div>
        )
      }
      else if (state === 5) {
        return(
          <div className="BenefitBadgeGrey">
            Fallido
          </div>
        )
       
      }

    }


      const [files, setFiles] = useState([]);
      const [comment,setComment] = useState();
      const [grade, setGrade] = useState([]);
    
      const optionsGrade = [
        { label: "1 estrella", value: 1 },
        { label: "2 estrellas", value: 2 },
        { label: "3 estrellas", value: 3 },
        { label: "4 estrellas", value: 4 },
        { label: "5 estrellas", value: 5 },
      ];

      const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        if (selectedFiles.length <= 5) {
          setFiles(selectedFiles);
        } else {
          alert("Solo se permiten hasta 5 archivos");
          // Limpiar la selección si se excede el límite
          event.target.value = null;
        }
      };

      const formatFiles = (files) => {
        return files.map((file) => ({
          content_file: URL.createObjectURL(file),
          content_type: file.type
        }));
      };
      

      async function handleUpload() {
        // Iterar sobre cada archivo
        for (const file of files) {
          // Llamar a la función BenefitAddRecieptAPI con el archivo y el ID de asignación
          await BenefitAddRecieptAPI(file, assignation.id);
        }
        // Llamar a la función BenefitAddGradeCommentAPI con el comentario, la calificación y el ID de asignación
        await BenefitAddGradeCommentAPI(comment, grade[0].value, assignation.id);
        window.location.reload();
      }
      

    

    function Buttons(props){
      const state = props.state;
      const receipts_qty = props.receipts_qty;
      if(state === 0){
        return(
          <div className="BenefitButtonGroup"> 
            <button className="BenefitButton" onClick={handleAccept} >
              Aceptar   
            </button>
            <button className="BenefitMiniButton" onClick={handleDecline}> 
              Rechazar
            </button>
          </div>
        )
      }
      else if (state === 1 && receipts_qty <= 0  ) {
        if(showUpload){
          return(
            <button className="BenefitButton" onClick={() => handleUpload()}>
              Enviar
            </button>
          )
        }
        else {
          return(
            <button className="BenefitButton" onClick={() => setShowUpload(true)}>
              Subir comprobantes
            </button>
          )  
        }
      }
      else{
        return (<div/>)
      } 
    }

    
  
      
    return(
      <div className="BenefitBox" style={{width:boxWidth}}>
        <Badge   state={assignation.state} receipts_qty={assignation.receipts.length} />
        <div className="BenefitBoxTop">
          <div className="BenefitLogoCategorie" >
          {icons.map((item, index) => {
            if (item.type ===assignation.benefit.benefit_type) {
              return (
                <div key={index}>
                  {item.icon}
                </div>
              );
            }
            return null;  // Si no coincide ningún tipo, no se mostrará ningún icono
          })}
          </div>
          <div className="BenefitTitle">  
            {assignation.benefit.brand.name}
            {assignation.benefit.attachment  && <a  href={assignation.benefit.attachment} target="_blank" rel="noopener noreferrer" download><ClipIcon  height="16px" width="16px" color="#C7506C"/></a>}
            
            <div className="BenefitDescription" >
              Reward Points : {assignation.benefit.reward_rule}
              <div>
              <div>
                  <div ref={textRef} style={showMore ? {} : { maxHeight: "2rem", overflow: "hidden" }}>
                    {reactElementFull}
                  </div>
              </div>
              { isBigger || overflowActive ? 
              (
                showMore ? (
                  <button
                    className="LinkButton"
                    onClick={() => setShowMore(!showMore)}
                  >
                    Ver menos
                  </button>
                ) : (
                  <button
                    className="LinkButton"
                    onClick={() => setShowMore(!showMore)}
                  >
                    ...Ver más
                  </button>
                )
              ) :
              ""
              }
             
              </div>
            </div>
          </div>

        </div>
        {showUpload ? 
        <div className="BenefitUpload">
        <div className="UploadContentBox" style={{width:"unset !important"}}>
        { files.length > 0  ?
            <div className="PhotoPlacement" style={{height:  "347px"}}>
              <CarouselContent 
                data={formatFiles(files)} 
                size={260} 
                ratioSquare={false} 
                isUpload={true} 
                onLoad={() => handleCarouselLoaded()}
              />
            </ div>
          :
          <div className="PhotoPlacement" style={{height: "347px"}}>
              <div className="UploadButton">
              <input
                id="fileInput"
                type="file"
                accept="image/*, video/*"
                multiple
                onChange={handleFileChange}
                style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
              />
              <label className="UploadLabel" htmlFor="fileInput" style={{ cursor: 'pointer' }}>Subir Archivos</label>
            </div>
          </ div>
        }
        
      </div>
      <div className="ProfileInputBox">
              Nota {false ? <span className="ErrorText"> {false} </span> : ""}
              <div className="HelpText">
              Siendo 1 estrella la peor experiencia y 5 estrellas la  mejor 
              </div>
              <div className={false ? "PostFormMultiSelect ErrorBorder" : "PostFormMultiSelect"}>
              <MultiSelect
              options={optionsGrade}
              value={grade}
              onChange={(item) => setGrade(item.length > 0 ? [item[item.length - 1]] : [])}
              labelledBy="Nota"
              closeOnChangedValue={true}
              hasSelectAll={false}
              overrideStrings={{ 
              "noOptions": "Sin resultados",
              "search": "Buscar",
              "selectSomeItems": "Elegir Nota",
              }}
              />
            </div>
             
              
            </div>
      <div className="BenefitInputBox"  >
          Comentarios
            <div className="BenefitInput">
            <Form.Control 
                  type="text"
                  as="textarea"
                  rows={3}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
              />
              
            <div>
                <div className="BenefitContador">
                  {comment ? comment.length : 0 } /160
                </div>
            </div>
            </div>
      </div>
     
        
      </div>
          :
          (assignation.state !== undefined && ([0, 1].includes(assignation.state)|| (assignation.user_grade>0 || assignation.user_comment || assignation.receipts.length > 0 ))? <div className="Divider" /> : "")
        }
        
        <div className="BenefitBoxBottom">
          {assignation.state === 0 &&
             <Counter start_time={assignation.sent_at} delta_time={assignation.benefit.time_to_accept} /> 
          }
          { assignation.state === 1 && assignation.receipts.length ==0 &&
             <Counter start_time={assignation.sent_at} delta_time={assignation.benefit.time_to_accept} />  
          }
          { (assignation.user_grade>0 || assignation.user_comment || assignation.receipts.length > 0 ) ? (!showDetails ?

            <button className="ShowDetailsButton" onClick={() => setShowDetails(true)}> Mostart Detalles  </button>  : 
            <button className="ShowDetailsButton" onClick={() => setShowDetails(false)}> Ocultar Detalles  </button> ) : ""
          }

          {showDetails  && 
          <div className="BenefitUploadAndComments">
            {assignation.user_grade > 0  &&
            <div> Nota : 
            {" " + assignation.user_grade}
             </div>}
            {assignation.user_comment &&
            <div> Comentario : <br/>
            {assignation.user_comment} 
            </div>}
            { assignation.receipts.length > 0 &&
              <div> 
                {assignation.receipts.map((receipt, index) => (
                    <div key={index}>
                      <a className="RecieptButton" href={receipt.receipt_file} target="_blank" rel="noopener noreferrer" download>Comprobante  n°{index + 1}</a>
                    </div>
                  ))}
              </div>  
            }
          </div>}
          <Buttons  state={assignation.state} receipts_qty={assignation.receipts.length} />
        </div>

      </div>
    )


    //<a href="#" onClick={() => openInNewTab(benefit_data.attachment)}> Archivo adjunto beneficio </a>
  }

  export default Benefit;