import React, { useEffect, useState,useRef } from "react";
import { ActivateAPI } from "../../API";
import { useParams } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import UserIcon from "../../assets/SVG/UserIcon"
import LockIcon from "../../assets/SVG/LockIcon";
import CBCLogo from  "../../assets/SVG/CBCLogo";
import CodeIcon from "../../assets/SVG/CodeIcon";
import ShowIcon from "../../assets/SVG/ShowIcon";
import WarningIcon from "../../assets/SVG/WarningIcon";


export default function Verify() {
  const params = useParams();
  const pre_email = params.params?.split(",")[1]?.substring(6);
  const pre_code = params.params?.split(",")[0]?.substring(5);
  const [email, setEmail] = useState(pre_email ? pre_email : null);
  const [code, setCode] = useState(pre_code ? pre_code.match(/.{1,2}/g) : [null,null,null,null] );
  const [errorMessage,setErrorMessage] = useState();



  useEffect(() => {
    setErrorMessage(null)
  }, [email,code]);



    const inputRefs = useRef([]);


    const handleKeyPress = index => () => {
      const current_value = inputRefs.current[index]?.value
      if (index < 3 && current_value.length === 2 ) {
          inputRefs.current[index + 1].focus();
        }
                   
    };

    const notNull = (element) => element !== null;

  



 
  return (
    <div className="PrincipalBox">
       <CBCLogo width="100" height="100" color="white" />
       <Form className="LoginForm" 
            onSubmit={(e) => {
              e.preventDefault();
              if(email && code.every(notNull)){
                ActivateAPI(email,code.join(""),null).then(res => {
                  if(res.status){
                    window.location.href= "/"
                  }
                  else{
                    setErrorMessage(res.message)
                  }
                });
              }
              if(!email ||  !code.every(notNull)){
                const message = ["Por favor completar ", (!email ? "email, " : ""),(!code.every(notNull) ? "codigo, " : "")]  
                const filteredMessage = message.filter(item => item !== "");
                if(filteredMessage.length === 2){
                  const final_message = filteredMessage.join(' ').slice(0,-2)
                  setErrorMessage(final_message)
                }
                else{
                  const last = filteredMessage.pop().slice(0,-2);
                  const final_message = filteredMessage.join(' ').slice(0,-2) + " y " + last;
                  setErrorMessage(final_message)
                }
              }
        }}>
        
        <div className="LoginText">
        Ingresa el código que enviamos a tu correo
        </div>
       
         
        <div className="CodeInput">
          {[1,2,3,4].map((inputnumber, index) => (
            <div className={!code.every(notNull) &&  errorMessage ?"LoginInput ErrorBorder" :  "LoginInput"}>
              <input
                key={index}
                type="text"
                placeholder=""
                value={code? code[index] : null }
                onKeyUp={handleKeyPress(index)}
                ref={el => (inputRefs.current[index] = el)}
                onChange={(e) => {
                  setCode(prevCode => {
                    const updatedCode = [...prevCode]; // Crear una copia del estado anterior
                    updatedCode[index] = e.target.value.substring(0, 2).toUpperCase(); // Actualizar el valor en el índice correspondiente
                    return updatedCode; // Devolver el nuevo estado actualizado
                  }); 
                }}
              />
          </div>
          ))}
        </div>
        <div className={!email &&  errorMessage ?"LoginInput ErrorBorder" :  "LoginInput"}>
          <UserIcon  width="28px" height="28px"/>
          <input
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value.trim().toLocaleLowerCase());
            }}
          />
        </div>
       

        <button className="RecentPostButton" type="Submit">
          Verificar mi cuenta 
        </button>
      </Form>
    </div>
  );
}
