import React, { useRef, useEffect, useState } from 'react';
import FilterIcon from '../../assets/SVG/FilterIcon';
import ChevronDownIcon from '../../assets/SVG/ChevronDownIcon';
import CloseIcon from '../../assets/SVG/CloseIcon';
import PlacesSearch from './PlacesSearch';
import { CategoryCountAPI } from '../../API';

export default function FilterMenu(props) {
    const catMenu = useRef(null);
    const [openSlide, setOpenSlide] = React.useState(false);
    const { albumFilter, tagFilter, setTagFilter, placeFilter,setPlaceFilter } = props;
    

    const optionsByAlbum = [
        {
            id: "0",
            type: "todo",
            options: [ { value: 1, label: "Ocasión Especial", album: "looks" },
            { value: 2, label: "Casual", album: "looks" },
            { value: 4, label: "Street Style", album: "looks" },
            { value: 8, label: "Restaurantes", album: "travel" },
            { value: 9, label: "Hoteles", album: "travel" },
            { value: 15, label: "Beauty", album: "lifestyle" },
            { value: 16, label: "Deco", album: "lifestyle" },
            ]
        },
        {
            id: "1",
            type: "look",
            options: [
                { value: 1, label: "Ocasión Especial", album: "looks" },
                { value: 2, label: "Casual", album: "looks" },
                { value: 3, label: "Work Outfit", album: "looks" },
                { value: 4, label: "Street Style", album: "looks" },
                { value: 5, label: "Deportivo", album: "looks" },
                { value: 6, label: "Tendencias", album: "tendencias" },
                { value: 7, label: "Estilo Bohemio", album: "looks" },
                    ]
        },
        {
            id: "3",
            type: "trends",
            options : [
            { value: 22, label: "Primavera ", album: "tendencias" },
            { value: 23, label: "Verano", album: "tendencias" },
            { value: 24, label: "Otoño", album: "tendencias" },
            { value: 25, label: "Invierno", album: "tendencias" }
                    ]
        },
        {
            id: "2",
            type: "travel",
            options : [ 
            { value: 8, label: "Restaurantes", album: "travel" },
            { value: 9, label: "Hoteles", album: "travel" },
            { value: 10, label: "Bares y Cafeterías", album: "travel" },
            { value: 11, label: "Panoramas Turísticos", album: "travel" },
            { value: 12, label: "Actividades al Aire Libre", album: "travel" },
            { value: 13, label: "Playas y Costas", album: "travel" },
            { value: 14, label: "Museos y Galerías", album: "travel" },
                    ]
        },
        {
            id: "4",
            type: "lifestyle",
            options : [
                { value: 15, label: "Beauty", album: "lifestyle" },
                { value: 16, label: "Deco", album: "lifestyle" },
                { value: 17, label: "Tecnología", album: "lifestyle" },
                { value: 18, label: "Regalos y Celebraciones", album: "lifestyle" },
                { value: 19, label: "Salud y Bienestar", album: "lifestyle" },
                { value: 20, label: "Viajes y Aventuras", album: "lifestyle" },
                { value: 21, label: "Cocina y Gastronomía", album: "lifestyle" }
          ]
        },
    ];

    const options = optionsByAlbum.find(option => option.id === albumFilter)?.options || [];
    const [filteredOptions, setFilteredOptions] = useState(options);

    useEffect(() => {
        const counts = [];
        options.forEach(option => {
            CategoryCountAPI(option.value).then(data => {
                counts[option.label] = data.count;
            });
        });
        console.log(counts);
    

        Promise.all(options.map(option => CategoryCountAPI(option.value)))
            .then(responses => {
                const validOptions = options.map((option, index) => {
                    if (responses[index]?.count > 0) {
                        return {
                            ...option,
                            count: responses[index].count
                        };
                    }
                    return null;
                }).filter(option => option !== null);
                setFilteredOptions(validOptions);
                console.log(validOptions);
            });
    }, [albumFilter]);

    const closeOpenMenus = (e) => {
        if (catMenu.current && openSlide && !catMenu.current.contains(e.target)) {
            setOpenSlide(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener("mousedown", closeOpenMenus);
        return () => {
            document.removeEventListener("mousedown", closeOpenMenus);
        };
    }, [openSlide]);

    const handleTagFilterClick = (subOption) => {
        if (tagFilter.includes(subOption)) {
            setTagFilter(tagFilter.filter(item => item !== subOption));
        } else {
            setTagFilter([...tagFilter, subOption]);
        }
        setOpenSlide(false);
    };

    return (
        
        filteredOptions.length > 0 ?
        (
        <div className="FilterMenu"  style={{marginTop : 'env(safe-area-inset-top)'}}>
            {!openSlide ? (
                <div className="FilterButton" onClick={() => setOpenSlide(true)}>
                    <FilterIcon />
                    {tagFilter.length>0 && <div className='BadgedNumber'> {tagFilter.length} </div> }
                    <ChevronDownIcon />
                </div>
            ) : (
                <div className="FilterOptionsMenu" ref={catMenu}>
                    { albumFilter == 2  &&
                            <PlacesSearch   placeFilter={placeFilter}   setPlaceFilter={setPlaceFilter} />
                    }
                    <div className="SmallHeader">Filtrar por      <button className='SmallestButton' onClick={() => setOpenSlide(false)}> <CloseIcon width='20px' height='20px' />  </button></div>
                    {filteredOptions.map((subOption) => (
                        <div key={subOption} className={`FilterOptions ${tagFilter.includes(subOption.value) ? 'Selected' : ''}`} onClick={() => handleTagFilterClick(subOption.value)}>
                            <input
                                type="checkbox"
                                className='myCheckBox'
                                id={`myCheckbox_${subOption.value}`}
                                checked={tagFilter.includes(subOption.value)}
                                readOnly
                            />
                            <label htmlFor={`myCheckbox_${subOption.value}`} className="checkbox-label">{subOption.label} ({subOption.count})</label>
                        </div>
                    ))}
                    <div className='FilterOptionsFinal'>
                        <div onClick={() => { setOpenSlide(false); setTagFilter([]); setPlaceFilter(-1) }} className='FilterOptionsButton'>
                            Quitar Filtros
                        </div>
                    </div>
                </div>
            )}
        </div>
        ):
        <div/> 
    );
}
