import { useNavigate } from "react-router-dom";


export function goBack(navigation) {
    sessionStorage.setItem('wentBack', 'true');
    navigation(-1); // Retroceder en el historial del navegador
  }

export const confirmLeave = (navigate) => {
  let hasShownAlert = false;

  const handleBeforeUnload = (e) => {
    if (!hasShownAlert) {
      e.preventDefault();
      const message = '¿Estás seguro que quieres salir? Los cambios no guardados se perderán.';
      e.returnValue = message;
      hasShownAlert = true;
      return message;
    }
  };

  const handlePopState = (e) => {
    if (!hasShownAlert) {
      e.preventDefault();
      const confirmLeave = window.confirm("¿Estás seguro que quieres salir? Los cambios no guardados se perderán.");
      hasShownAlert = true;
      if (confirmLeave) {
        goBack(navigate);
      } else {
        // Push a new state to prevent going back
        window.history.pushState(null, '', window.location.pathname);
        hasShownAlert = false; // Reset flag if user cancels
      }
    }
  };

  window.addEventListener('beforeunload', handleBeforeUnload);
  window.addEventListener('popstate', handlePopState);
  // Push initial state
  window.history.pushState(null, '', window.location.pathname);
  
  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
    window.removeEventListener('popstate', handlePopState);
  };
};