import React, { useState, useEffect,useRef } from "react";
import Carousel from "react-simply-carousel";
import {  animateScroll as scroll } from 'react-scroll';

export default function SliderMenu(props) {
  var options = [
    { label: "todo", value: "0", index: 0 },
    { label: "looks", value: "1", index: 1 },
    { label: "travel", value: "2", index: 2 },
    {label: "trends", value: "3", index: 3 }, //solo sube admins
    //{ label: "beauty", value: "5" },
    //{ label: "lifestyle", value: "4" },
    { label: "oz", value: "7", index: 4 },
  ];

  console.log(props.albumFilter);
  const setAlbumFilter = props.setAlbumFilter;
  const [activeSlide, setActiveSlide] = useState(options.find(option => option.value === props.albumFilter).index);
  const [position, setPosition] = useState(0)
  const fixed = props.fixed;
  const carouselRef = useRef(null);
  
  if (fixed) {
    // Borra el objeto con el label "Trends" de options
    options = options.filter(option => option.val !== "3");
  }

  useEffect(() => {
    console.log(activeSlide);
    setAlbumFilter(options.find(option => option.index === activeSlide).value);
    const options_scroll = {
      duration: 0,
      delay: 0,
      smooth: "linear"
    }
    scroll.scrollToTop(options_scroll);
  }, [activeSlide]);


  useEffect(() => {
    // Función para manejar el tamaño de la ventana y ajustar la posición de los elementos Fade
    function handleResize() {
      if (window.innerWidth <= 480) {
        // Si la ventana es menor o igual a 480px, establecer la posición en 0
        setPosition(0);
      } else {
        // Si la ventana es mayor que 480px, calcular el desplazamiento
        const displacement = (window.innerWidth - 480) / 2;
        setPosition(displacement);
      }
    }

    // Llamar a handleResize inicialmente para configurar la posición inicial
    handleResize();

    // Agregar un listener para el evento de cambio de tamaño de la ventana
    window.addEventListener('resize', handleResize);

    // Eliminar el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSlideClick = (index) => {
    setActiveSlide(index);
    if (carouselRef.current) {
      carouselRef.current.goToSlide(index);
    }
  };

  return (
    <div className={fixed ? "SliderMenuBackground" : "SliderMenuBackgroundNotFixed"}  style={{marginTop : fixed ? 'env(safe-area-inset-top)' : "none"}} >
      <center>
        { window.innerWidth < 500 && (
          <Carousel
            containerProps={{
              className: "SliderMenuCarousel",
            }}
            activeSlideIndex={activeSlide}
            activeSlideProps={{
              className: "ActiveSlide",
            }}
            onRequestChange={setActiveSlide}
            forwardBtnProps={{
              children: "",
              style: {
                width: 0,
                height: 0,
                minWidth: 0,
                alignSelf: "center",
                border: "none",
                background: "none",
              },
            }}
            backwardBtnProps={{
              children: " ",
              style: {
                width: 0,
                height: 0,
                minWidth: 0,
                alignSelf: "center",
                border: "none",
                background: "none",
              },
            }}
            dotsNav={{
              show: false,
              itemBtnProps: {
                style: {
                  height: 0,
                  width: 0,
                },
              },
              activeItemBtnProps: {
                style: {
                  height: 0,
                  width: 0,
                },
              },
            }}
            itemsToShow={4}
            centerMode={true}
          >
            {options.map((option, i) => (
              <p
                key={i}
                className={activeSlide === i ? "SliderMenuItem ActiveSlide" : "SliderMenuItem"}
                onClick={() => setActiveSlide(i)}
              >
                {option.label.toUpperCase()}
              </p>
            ))}
          </Carousel>
        
        )}
        { window.innerWidth < 500 && (
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
        <div className={fixed ?  "SliderRectangle"  : "SliderRectangleNotFixed"} style={{margin: "0 auto"}} />
        </div>
        )}
      
        { window.innerWidth < 500 && (
          <>
          <div className={fixed ?  "Fade LeftFade"   : ""} style={fixed ? {left : position } : {}}/>
          <div className={fixed ?  "Fade RightFade"  : ""} style={fixed ? {right : position } : {}}/>
          </>
        )}
        
        
        { window.innerWidth >= 500 && (
          <div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
            {options.map((option, i) => (
              option.value === props.albumFilter ? 
                <p key={i} onClick={() => setActiveSlide(i)}  className="SliderMenuItem ActiveSlide" style={{textDecoration: "underline", textUnderlineOffset: "16px", textDecorationThickness: "3px"}}>{ option.label.toUpperCase()}</p>
            :
                <p key={i} onClick={() => setActiveSlide(i)} className="SliderMenuItem">{option.label.toUpperCase()}</p>
            ))}
          </div>
        )}

          </center>
    </div>
  );
}
