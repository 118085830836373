import React, { useEffect, useState } from "react";
import { MyFeedAPI, fetchMoreAPI } from "../../API";
import FilterMenu from "../Components/FilterMenu";
import PostScreen from "../Components/Post";
import SizeMenu from "../Components/SizeMenu";
import InfiniteScroll from "react-infinite-scroll-component";
import SliderMenu from "../Components/SliderMenu";
import Masonry from "react-masonry-css";
import Spinner from "react-bootstrap/Spinner";
import NoContentMessage from "../Components/NoContentMessage";
import ScrollTracker from "../Components/ScrollTracker";
import { Element } from 'react-scroll';

import { useNavigate, useParams} from 'react-router-dom';

function FeedScreen() {
  const params = useParams();
  var options = [
    { label: "todo", value: "0" },
    { label: "trends", value: "3" }, //solo sube admins
    { label: "looks", value: "1" },
    { label: "travel", value: "2" },
    // { label: "beauty", value: "5" },
    //{ label: "lifestyle", value: "4" },
    { label: "oz", value: "7" },
  ];

  const selectedOption = options.filter(option => option.label ===  params.albumFilter)[0]
  const [data, setData] = React.useState();
  //const [wentBack, setWentBack] = React.useState(sessionStorage.getItem('wentBack')|| false );
  const [wentBack, setWentBack] = React.useState(false);
  const [next, setNext] = React.useState();
  const [albumFilter, setAlbumFilter] = React.useState(selectedOption?.value || "0");
  const [tagFilter, setTagFilter] = React.useState(params.tagFilterFromUrl ? params.tagFilterFromUrl.split(",") : []);
  const [placeFilter, setPlaceFilter] = React.useState(-1);
  const [columns, setColumns] = React.useState("m");
  const [message,setMessage] = React.useState("Estamos cargando el mejor contenido para ti...");
  const [isLoading,setIsLoading] = React.useState(true);
 // const [areTherePrevious,setAreTherePrevious] = React.useState(sessionStorage.getItem('previousPage') ? true : false);
  const [areTherePrevious,setAreTherePrevious] = React.useState(false);
  const [breakpointCols, setBreakpointCols] = React.useState({
    default: 4,
    1300: 3,
    900: 2,
  });
  const navigate = useNavigate();
  //const [currentPage, setCurrentPage] = React.useState(sessionStorage.getItem('currentPage') || null);
  //const [previousPage, setPreviousPage] = React.useState(sessionStorage.getItem('previousPage') || null);
  const [currentPage, setCurrentPage] = React.useState(null);
  const [previousPage, setPreviousPage] = React.useState(null);

  useEffect(() => {
    const fetchData = async () => { 
        const res = await MyFeedAPI(albumFilter, tagFilter, placeFilter);
        setIsLoading(false);
        if (res.results.length === 0){
          setMessage("No se encontro contenido relacionado con este filtro");
        }
        setData(res.results);
        setNext(res.next);
        
        // Guardar datos de navegación en sessionStorage
        //sessionStorage.setItem('currentPage', JSON.stringify(res.results));
        //sessionStorage.setItem('nextPage', res.next);
        if (res.previous) {
          //sessionStorage.setItem('previousPage', res.previous);
          setAreTherePrevious(true);
        }
    };
    // Si hay wentBack y previousPage, usar los datos guardados
    if (wentBack && sessionStorage.getItem('currentPage')) {
      const currentPageData = sessionStorage.getItem('currentPage');
      if (currentPageData) {
        setData(JSON.parse(currentPageData));
      }
      setNext(sessionStorage.getItem('nextPage'));
      setPreviousPage(sessionStorage.getItem('previousPage'));
      setAreTherePrevious(true);
    } else {
      fetchData();
    }
  
    if (albumFilter && albumFilter !== "0") {
      navigate(`/feed/${options.find(option => option.value === albumFilter)?.label}/`);
    }
    if (albumFilter && albumFilter === "0") {
      navigate(`/feed/`);
    }
  }, [albumFilter, tagFilter, placeFilter]);
  

  const fetchMore = () => {
    fetchMoreAPI(next).then((res) => {
      setData((prevData) => {
        const newData = res?.results ? [...prevData, ...res.results] : prevData;
        // Actualizar sessionStorage con los nuevos datos
        //sessionStorage.setItem('currentPage', JSON.stringify(res.results));
        //sessionStorage.setItem('nextPage', res.next);
        if (res.previous) {
          //sessionStorage.setItem('previousPage', res.previous);
          setAreTherePrevious(true);
        }
        return newData;
      });
      setNext(res.next);
    });
  };

  // Esto es para no mostrar nada mientras se carga el primer archivo de cada carousel

  const [loadedCarousels, setLoadedCarousels] = useState(0);
  const [showLoaded, setShowLoaded] = useState(false);
  const [loaded,setLoaded] = useState(false);
  const handleCarouselLoaded = () => {
    setLoadedCarousels(prevCount => prevCount + 1);
  };

  useEffect(() => {
    if(!showLoaded &&  data && (data.length === loadedCarousels || loadedCarousels >7 )){
      console.log('Todas los posts han sido cargados');
      setShowLoaded(true);
    }
    if(data && data.length === loadedCarousels){
      setLoaded(true);
    }
  }, [loadedCarousels]);
  

  const handleLoadPrevious = async () => {
    setIsLoading(true);
    try {
      const res = await fetchMoreAPI(previousPage);
      if (res?.results) {
        setData(res.results);
        setNext(res.next);
        setPreviousPage(res.previous);
        //sessionStorage.removeItem('wentBack');
        setWentBack(false);
      }
    } catch (error) {
      console.error('Error loading previous posts:', error);
    }
    setIsLoading(false);
  };

  return (
    <div>
      {/*<ScrollTracker data={data} next={next} loaded={loaded} wentBack={wentBack}/> */}
      <SliderMenu albumFilter={albumFilter} setAlbumFilter={setAlbumFilter} setTagFilter={setTagFilter} fixed={true} />
      <div className="HeaderSpacer" style={{ height: "98px", width: "30px" }} />
      <FilterMenu albumFilter={albumFilter} tagFilter={tagFilter} setTagFilter={setTagFilter} placeFilter={placeFilter}  setPlaceFilter={setPlaceFilter} />
      <SizeMenu props={{ columns: columns, setBreakpointCols: setBreakpointCols, setColumns: setColumns }} />
      <InfiniteScroll
        dataLength={data ? data.length : 5}
        next={fetchMore}
        hasMore={next ? true : false}
        loader={
          <div className="Loader">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        }
      >
        
         <Masonry
          breakpointCols={breakpointCols}
          className={columns === "l" ? "my-masonry-grid my-masonry-grid-large" : "my-masonry-grid"}
          columnClassName="my-masonry-grid_column"
          >
          {wentBack && loaded && areTherePrevious && (
            <div className="LoadPreviousButton">
              <button 
                onClick={handleLoadPrevious}
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#fff',
                  border: '1px solid #ddd',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  margin: '20px auto',
                  display: 'block',
                  width: 'fit-content'
                }}
              >
                Cargar Anteriores
              </button>
            </div>
          )}
          {data && data.length > 0 
            ? data.map((post, index) => {
              return (
                <Element name={"PostContainer_"+ post.id} className="element">
                 <PostScreen  className="PostScreen" key={post.id} data={post} size={columns} onLoad={handleCarouselLoaded}/>
                </Element>
               
              );
            })
            :   <NoContentMessage customMessage={message} isLoading={isLoading} /> }
        </Masonry>
        
        <div className="FeedSpacer" />
      </InfiniteScroll>
      
    </div>
  );
}

export default FeedScreen;
