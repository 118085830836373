import "./app/css/StandarStyle.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import {
  MeAPI,
} from "./app/API";
import React, { useEffect, useState } from "react";
import { PrivateRoute } from "./app/PrivateRoute";

import FeedScreen from "./app/screens/MainFinal/FeedScreen";
import Wishlist from "./app/screens/MainFinal/Wishlist";
import FullPostScreen from "./app/screens/MainFinal/FullPostScreen";
import EditProfile from "./app/screens/MainFinal/EditProfile";
import Profile from "./app/screens/MainFinal/Profile";
import SearchBar from "./app/screens/MainFinal/SearchBar";
import Benefits from "./app/screens/MainFinal/Benefits";
import Search from "./app/screens/MainFinal/Search";
import NavBar from "./app/screens/layout/NavBar";
import Bottombar from "./app/screens/layout/BottomBar";
import AlbumPosts from "./app/screens/MainFinal/AlbumFeed";
import Notifications from "./app/screens/MainFinal/Notifications";
import AddPhotos from "./app/screens/MainFinal/AddPhotos";
import ModalLogin from "./app/screens/Components/ModalLogin";
import ModalCreator from "./app/screens/Components/ModalCreator";
import Product from "./app/screens/MainFinal/Product";
import Config from "./app/screens/MainFinal/Config";
import FAQ from "./app/screens/MainFinal/FAQ";
import OurHistory from "./app/screens/MainFinal/OurHistory";
import TermsandConditions from "./app/screens/MainFinal/TermsandConditions";
import FAQRewards from "./app/screens/MainFinal/FAQRewards";
import BenefitsLevel from "./app/screens/MainFinal/BenefitsLevel";
import EarnPoints from "./app/screens/MainFinal/EarnPoints";
import Help from "./app/screens/MainFinal/Help";
import FullPostEditScreen from "./app/screens/MainFinal/FullPostEditScreen";
import Login from "./app/screens/MainFinal/Login";
//import UploadStatus from "./app/screens/Components/isUploading";
import Activate from "./app/screens/MainFinal/Activate";
import ResetPassword from "./app/screens/MainFinal/ResetPassword";


import Registro from "./app/screens/MainFinal/Register";
import ExclusiveRegister from "./app/screens/MainFinal/ExclusiveRegister";
import Verify from "./app/screens/MainFinal/Verify";

// import Test from "./app/screens/main/Test";


function App() {
  const [me, setMe] = useState();
  const [show, setShow] = useState(false);
  const [showCreator, setShowCreator] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      if(!me){
        MeAPI().then( res => {
          setMe( JSON.parse(localStorage.getItem("me")));
        });
      }
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const token = localStorage.getItem("token");
      if (token) {
        const refresh = localStorage.getItem("refresh");
        console.log("Refreshing token");
      }
    }, 900000);
    return () => clearInterval(intervalId);
  }, []);



  return (
    <div className="App"  
    //style={{ paddingTop: 'env(safe-area-inset-top)'}}>
    >

      <div style={{paddingTop:'env(safe-area-inset-top)', width:"100%",background:"white",position:"fixed",top:0,zIndex:200}} />
      {/* <UploadStatus /> */}
      <Router>
        <div style={{ paddingTop: 'env(safe-area-inset-top)' }}>
          <NavBar setShow={setShow}  me={me} />
          <Routes>
            <Route path="/" element={<Navigate to="/feed" replace />} />
            <Route exact path="/feed/:albumFilter/:tagFilter/:currentFromUrl" element={<FeedScreen />} />
            <Route exact path="/feed/:albumFilter/:tagFilter" element={<FeedScreen />} />
            <Route exact path="/feed/:albumFilter/" element={<FeedScreen />} />
            <Route path="/feed" element={<FeedScreen />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register/:params" element={<Registro />} />
            <Route exact path="/@:name/post/:id" element={<FullPostScreen />} />
            <Route exact path="/@:name" element={<Profile />} />
            <Route exact path="/product/:id" element={<Product />} />
            <Route exact path="/search/:name" element={<Search />} />
            <Route exact path="/searchbar" element={<SearchBar />} />
            <Route exact path="/activate/:params" element={<Activate />} />
            <Route exact path="/activate/" element={<Activate />} />
            <Route exact path="/resetpassword" element={<ResetPassword />} />
            <Route exact path="/@:name/posts/:album" element={<AlbumPosts />} />
            <Route exact path="/help/" element={<Help />} />
            <Route exact path="/FAQ/" element={<FAQ />} />
            <Route exact path="/ourhistory/" element={<OurHistory />} />
            <Route exact path="/termsandconditions/" element={<TermsandConditions />} />
            <Route exact path="/FAQRewards/" element={<FAQRewards />} />
            <Route exact path="/earnpoints/" element={<EarnPoints />} />
            <Route exact path="/benefitslevel/" element={<BenefitsLevel />} />
            <Route exact path="/@:name/posts/" element={<AlbumPosts />} />
            <Route exact path="/register" element={<Registro />} />
            <Route exact path="/exclusiveregister/:params" element={<ExclusiveRegister />} />
            <Route exact path="/verify/:params" element={<Verify />} />
            

            <Route exact path="/" element={<PrivateRoute />}>
              <Route exact path="/@:name/post/:id/edit" element={<FullPostEditScreen />} />
              <Route exact path="/@:name/edit" element={<EditProfile />} />
              <Route exact path="/@:name/settings" element={<Config />} />
              <Route exact path="/mywishlist" element={<Wishlist />} />
              <Route exact path="/notifications" element={<Notifications />} />
              <Route exact path="/new" element={<AddPhotos />} />
              <Route exact path="/benefits" element={<Benefits />} />
              {/* <Route exact path="/test" element={<Test />} /> */}
            </Route>
          </Routes>
        </div>
        <Bottombar setShow={setShow} setShowCreator={setShowCreator} me={me} />

        {show && <ModalLogin setShow={setShow} />}
        {showCreator && <ModalCreator setShow={setShowCreator} />}
       
      </Router>
    </div>
  );
}

export default App;
