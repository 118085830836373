import React, { useState, useEffect, useRef } from 'react';
import { PinterestShareButton, WhatsappShareButton } from "react-share";
import ShareIcon from '../../assets/SVG/ShareIcon';
import ClipIcon from '../../assets/SVG/ClipIcon';
import CloseIcon from '../../assets/SVG/CloseIcon';
import { WhatsappIcon, PinterestIcon } from '../../assets/SVG/SocialIcons';
import MoreIcon from '../../assets/SVG/MoreIcon';
import { DownloadContent } from "../../API";
import { ShareAPI } from '../../API';

function ShareModal(props) {
  const buttonRef = useRef(null);
  const modalRef = useRef(null);
  const id = props.id;
  const resource = props.resource;

  const [link,setLink] = useState(window.location.href);
  const title = props.title;
  const contents = props.contents;
  const shareBody = props.shareBody;
  const [showModal, setShowModal] = useState(false);
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
  const [copied, setCopied] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setCopied(false);
  };

  const handleShowModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const buttonRect = buttonRef.current.getBoundingClientRect();
    setModalPosition({ x: buttonRect.x, y: buttonRect.y + buttonRect.height });
    setShowModal(!showModal);
  };

  const calculateLeft = (x) => {
    if (x + 239 + 5 > window.innerWidth) {
      return x - (x + 320 - window.innerWidth);
    } else {
      return x - 50;
    }
  };

  const handleShare = async () => {
    const fetchDataAndDownloadFiles = async () => {
      try {
        const files = await DownloadContent(contents);
        return files;
      } catch (error) {
        console.error('Error al obtener o descargar los archivos:', error);
        return [];
      }
    };

    try {
      if (navigator.canShare) {
        const files = await fetchDataAndDownloadFiles();
        if (files.length > 0) {
          await navigator.share({
            title: title,
            text: shareBody,
            url: link,
            files: files
          });
        } else {
          await navigator.share({
            title: title,
            text: shareBody,
            url: link
          });
        }
      } else {
        await navigator.share({
          title: title,
          text: shareBody,
          url: link
        });
      }
    } catch (error) {
      console.error('Error al compartir:', error);
    }
  };

  // Cerrar el modal al hacer scroll, clic fuera o toque en pantalla táctil
  useEffect(() => {
    const handleScroll = () => {
      if (showModal) {
        handleCloseModal();
      }
    };

    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
        handleCloseModal();
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [showModal]);




  return (
    <>
      <button  className="FullPostHeaderButton ShareOpenModal" ref={buttonRef} onClick={(e) => handleShowModal(e)}>
        <ShareIcon width="20px" height="20px" />
      </button>
      {showModal &&
        <div className='ShareModalOverlay' ref={modalRef} style={{ top: modalPosition.y - 70, left: calculateLeft(modalPosition.x + 20) }}>
          <div className='ShareModalContent'>
            <div className='ShareModalButton'>
              <WhatsappShareButton url={link} title={shareBody}>
                <WhatsappIcon />
              </WhatsappShareButton>
            </div>
            <div className='ShareModalButton'>
              <PinterestShareButton url={link} media={contents[0].content_file} description={shareBody}>
                <PinterestIcon />
              </PinterestShareButton>
            </div>
            <div className='ShareModalButtonBox'>
              <div className='ShareModalButton' onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(link).then(() => {
                  setCopied(true);
                  setTimeout(() => {
                    handleCloseModal();
                  }, 500);
                });
              }}>
                <ClipIcon />
              </div>
              {copied ? "Copiado!" : ""}
            </div>
            {navigator.canShare &&
              <div className='ShareModalButton'>
                <button onClick={() => handleShare()}>
                  <MoreIcon />
                </button>
              </div>
            }
            <div className='CloseModal' onClick={handleCloseModal}>
              <CloseIcon />
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default ShareModal;
